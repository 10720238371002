export const SIZES = [
  {
    var: 'wrap',
    size: '1435px',
  },
  {
    var: 'gap',
    size: '120px/40px/16px',
  },
  {
    var: 'halfGap',
    size: 'calc(var(--gap) / 2)',
  },
  {
    var: 'header-h',
    size: '80px/64px/56px',
  },
];

export const COLORS = [
  {
    var: 'white',
    color: '#FFFFFF',
  },
  {
    var: 'black',
    color: '#000000',
  },
  {
    var: 'text-1',
    color: '#1b2228',
  },
  {
    var: 'text-2',
    color: '#3c4c5a',
  },
  {
    var: 'text-3',
    color: '#95adc3',
  },
  {
    var: 'line',
    color: '#e2eaf0',
  },
  {
    var: 'background',
    color: '#f5f6f9',
  },
  {
    var: 'main',
    color: '#f83737',
  },
  {
    var: 'gradient',
    color: 'linear-gradient(90deg, #f83737 1.71%, #d41f81 100%)',
  },
  {
    var: 'transparent',
    color: 'rgba(255, 255, 255, 0)',
  },
];

export const TRANSITIONS = [
  {
    var: 'tr-mode-default',
    value: 'cubic-bezier(0.65, 0, 0.35, 1)',
  },
  {
    var: 'tr-mode-bounce',
    value: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  },
];

export const BUTTONS = [
  {
    class: 'cr-btn',
    bg_color: '',
  },
  {
    class: 'cr-btn cr-btn--default',
    bg_color: '',
  },
  {
    class: 'cr-btn cr-btn--large',
    bg_color: '',
  },
  {
    class: 'cr-btn cr-btn--icon cr-btn--large',
    bg_color: '',
    icon: 'corner-arrow-small-bold',
  },
  {
    class:
      'cr-btn cr-btn--icon cr-btn--large cr-btn--icon-red-bg cr-btn--icon-left-corners-initial',
    bg_color: '',
    icon: 'corner-arrow-small-bold',
  },
  {
    class: 'cr-btn cr-btn--icon cr-btn--icon-red-bg',
    bg_color: '',
    icon: 'corner-arrow-small-bold',
  },
  {
    class: 'cr-btn cr-btn--icon cr-btn--icon-red-txt',
    bg_color: '',
    icon: 'corner-arrow-small-bold',
  },
  {
    class:
      'cr-btn cr-btn--icon cr-btn--icon-red-txt cr-btn--icon-red-txt_white-bg',
    bg_color: '#ccc',
    icon: 'corner-arrow-small-bold',
  },
];

export const ICONS = [
  {
    name: 'cirrus-logo-black',
  },
  {
    name: 'cirrus-logo-white',
  },
  {
    name: 'search',
  },
  {
    name: 'corner-arrow-small',
  },
  {
    name: 'corner-arrow-small-bold',
  },
  {
    name: 'checked',
  },
  {
    name: 'checked-circled',
  },
  {
    name: 'triangle-arrow',
  },
  {
    name: 'chevron-down',
  },
  {
    name: 'gas-station',
  },
  {
    name: 'order',
  },
  {
    name: 'social-facebook',
  },
  {
    name: 'social-twitter',
  },
  {
    name: 'social-instagram',
  },
  {
    name: 'social-linkedIn',
  },
  {
    name: 'arrow-left',
  },
  {
    name: 'arrow-right',
  },
  {
    name: 'arrow-down',
  },
  {
    name: 'phone-call',
  },
  {
    name: 'close',
  },
];

export const UNDERLINE_LIST = [
  {
    markered: true,
    text: 'All news',
  },
  {
    text: 'Market analytics',
  },
  {
    text: 'New in Cirrusoft',
  },
  {
    text: 'Future of retail',
  },
  {
    text: 'Data management',
  },
];

export const LEFT_BORDER_LIST = [
  {
    text: 'Fast roll-out & easy operation',
  },
  {
    markered: true,
    text: 'Hardware independent',
  },
  {
    text: 'Support all payment systems',
  },
  {
    text: 'Realtime data & insightful reports',
  },
  {
    text: 'Flexible pricing models',
  },
];

export const CHECKED_LIST = [
  {
    text: 'Useful articles on the future of retail',
  },
  {
    text: 'Market reports',
  },
  {
    text: 'Cirrusoft news and updates',
  },
];
