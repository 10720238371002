import React from 'react';
import Icon from 'components/UI/Icon';
import 'styles/styles.scss';
import 'styles/framework/framework.scss';
import {
  BUTTONS,
  CHECKED_LIST,
  COLORS,
  ICONS,
  LEFT_BORDER_LIST,
  SIZES,
  TRANSITIONS,
  UNDERLINE_LIST,
} from 'constants/framework/vars';

const Framework = () => {
  return (
    <div className={'cr-framework'}>
      <div className={'cr-wrap'}>
        <p className={'cr-h1 cr-txt-center cr-txt-up'}>Framework</p>
        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Sizes</p>
          <hr />
          <ul className="cr-lead cr-framework-sizes">
            {SIZES.map((size) => {
              return (
                <li key={size.var}>
                  <strong>var(--{size.var}):&#160;</strong>
                  {size.size};
                </li>
              );
            })}
          </ul>
        </div>
        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Colors</p>
          <hr />
          <ul className="cr-lead cr-framework-colors">
            {COLORS.map((color) => {
              return (
                <li key={color.var}>
                  <span style={{ background: color.color }}></span>
                  <strong>var(--{color.var}):&#160;</strong>
                  {color.color};
                </li>
              );
            })}
          </ul>
        </div>
        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Typography</p>
          <hr />
          <h1 className={'cr-h1'}>.cr-h1 - 88/38px</h1>
          <h2 className={'cr-h2'}>.cr-h2 - 48/24px</h2>
          <h3 className={'cr-h3'}>.cr-h3 - 30/18px</h3>
          <h4 className={'cr-h4'}>.cr-h4 - 24/18px</h4>
          <h5 className={'cr-h5'}>.cr-h5 - 20/14px</h5>
          <p className={'cr-lead'}>.cr-lead - 24/16px</p>
          <p className={'cr-reg'}>.cr-reg - 20/14px</p>
          <p className={'cr-sub'}>.cr-sub - 15px</p>
          <p className={'cr-sub-2'}>.cr-sub-2 - 14/10px</p>
          <p className={'cr-subheader'}>.cr-subheader - 18/12px</p>
          <p className={'cr-subheader-2'}>.cr-subheader-2 - 16px</p>
          <p className={'cr-subheader-3'}>.cr-subheader-3 - 18/14px</p>
          <p className={'cr-subheader-4'}>.cr-subheader-4 - 15/12px</p>
        </div>

        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Others</p>
          <hr />
          <p className={'cr-txt-center'}>.cr-txt-center</p>
          <p className={'cr-txt-up'}>.cr-txt-upper</p>
          <p className={'cr-txt-medium'}>.cr-txt-medium</p>
          <p className={'cr-txt-strong'}>.cr-txt-strong</p>
          <a className={'cr-link'} href="#link" target="_blank">
            .cr-link
          </a>
          <p>
            <span className={'cr-over-line cr-over-line--red'}>
              .cr-over-line .cr-over-line--red
            </span>
          </p>
        </div>

        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Text colors</p>
          <hr />
          <p className={'cr-white'} style={{ background: '#ccc' }}>
            .cr-white
          </p>
          <p className={'cr-text-1'}>.cr-text-1</p>
          <p className={'cr-text-2'}>.cr-text-2</p>
          <p className={'cr-text-3'}>.cr-text-3</p>
          <p className={'cr-main'}>.cr-main</p>
          <p className={'cr-gradient'}>.cr-gradient</p>
        </div>

        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Transitions mode</p>
          <hr />
          <ul className="cr-lead cr-framework-transitions">
            {TRANSITIONS.map((item) => {
              return (
                <li key={item.var}>
                  <span>
                    <strong>${item.var}:&#160;</strong>
                  </span>
                  <i style={{ transitionTimingFunction: item.value }}>
                    {item.value};
                  </i>
                </li>
              );
            })}
          </ul>
        </div>

        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Buttons</p>
          <hr />
          <ul className="cr-lead cr-framework-btns">
            {BUTTONS.map((button, i) => {
              return (
                <li
                  key={i}
                  style={{ background: button.bg_color, padding: '8px 0' }}
                >
                  <button className={button.class}>
                    <span>{button.class}</span>
                    {button.icon && <Icon name={button.icon} />}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>SVG Icons</p>
          <hr />
          <ul className="cr-lead cr-framework-icons">
            <li>
              <span>&#60;Icon name="name" /&#62;</span>
            </li>
            {ICONS.map((icon) => {
              return (
                <li key={icon.name} style={{ padding: '8px 0' }}>
                  <Icon name={icon.name} /> - <span>{icon.name}</span>
                </li>
              );
            })}
          </ul>
        </div>

        <div className={'cr-framework-block'}>
          <hr />
          <p className={'cr-reg'}>Lists</p>
          <hr />
          <p className={'cr-framework-gap'}>
            <strong>.cr-underline-list</strong>
          </p>
          <ul className={'cr-underline-list'}>
            {UNDERLINE_LIST.map((item) => {
              return (
                <li key={item.text} className={item.markered && 'cr-markered'}>
                  {item.text}
                </li>
              );
            })}
          </ul>

          <p className={'cr-framework-gap'}>
            <strong>.cr-left-border-list</strong>
          </p>

          <ul className={'cr-left-border-list'}>
            {LEFT_BORDER_LIST.map((item) => {
              return (
                <li key={item.text} className={item.markered && 'cr-markered'}>
                  <span>{item.text}</span>
                </li>
              );
            })}
          </ul>

          <p className={'cr-framework-gap'}>
            <strong>.cr-checked-list</strong>
          </p>

          <ul className={'cr-checked-list'}>
            {CHECKED_LIST.map((item) => {
              return (
                <li key={item.text}>
                  <span>{item.text}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Framework;
